/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Copyright() {
  return (
    <Typography variant="h6" color="text.secondary" align="right" mr={5} fontSize={15}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        UTN
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Footer() {

  return (
    <Box component="footer" sx={{ bgcolor: 'background.paper', py: 6, width:'90%',ml:'5%' }}>
      <Container maxWidth="lg">
        <Typography
          width={'80%'}
          variant="h6"
          fontWeight={'bold'}
          style={{display:'inline-block'}}
          fontSize={15}
        >
          본사 : 서울시 동대문구 천호대로 307, 909호 (답십리동, 클래식타워)
          <br />
          대표전화 : 02-6952-2352
          <br />
          회사명 : (주)유티엔 / 대표 : 임태현 / 사업자번호 : 448-86-02107
        </Typography>
        <img src={'/img/footer/footer_logo.png'} style={{width:100}}/>
      </Container>
      <div style={{backgroundColor:'darkgrey' ,height:1,margin:'2%'}}></div>
      <div style={{display:'flex',justifyContent:'space-between'}}>
        <img src={'/img/footer/footer_title.png'} style={{marginLeft:20,width:400}}/>
        <Copyright />
      </div>
    </Box>
  );
}
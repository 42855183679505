/* eslint-disable jsx-a11y/alt-text */
import {  Paper, Typography } from "@mui/material";
import React from "react";

export default function MainPage(props: any) {

  return (
    props.page === 1 ?
    <Paper elevation={3} sx={{padding:3,marginTop:5,width:'90%',marginLeft:'5%',marginRight:'5%'}}>
      <img src={'img/mainpage/mainpage1_title.png'} style={{width:'50%'}}/>
      <Typography variant="h6" fontWeight={'bold'} mt={2} mb={2} fontSize={15}>치과병원에서 발생하는 교정규격 정보를 치기공소에 메일 또는 발주서 등으로 전달하는
        DeCoupling Process가 지금까지의 업무처리방식 입니다.
      </Typography>
      <img src={'img/mainpage/mainpage1.png'} style={{width:'80%',marginLeft:'10%'}}/>
      <Typography variant="h5" fontWeight={'bold'} color={'darkblue'} mt={2} fontSize={20}>이제부터는 치과병원과 치기공소 간의 정보가 Dent Cloud Platform을 통한 
        Coupling Process로 진화해 가야 합니다.
      </Typography>
    </Paper>
    :
    props.page === 2 ?
    <Paper elevation={3} sx={{padding:3,marginTop:5,width:'90%',marginLeft:'5%',marginRight:'5%'}}>
      <img src={'img/mainpage/mainpage2_title.png'} style={{width:'50%'}}/>
      <Typography variant="h6" fontWeight={'bold'} mt={2} mb={2} fontSize={15}>uTnERP는 ‘사용자가 자기 권한에 준하여 편하게 정보에 접근할 수 있도록 하려면 어떻게 할 것인가?’를
        염두에 두고 정보시스템을 설계하였습니다.
        <br/>  
        이는 업무처리속도를 높이는 측면에서 효과를 나타낼 것이며, 7가지 특징으로 요약됩니다.
      </Typography>
      <div style={{marginLeft:'5%',marginRight:'5%'}}>
      <Typography variant="h5" fontWeight={'bold'} color={'darkblue'} mt={5} mb={2} width={'60%'} display={"inline-block"} fontSize={20}>
        STEP 1. &nbsp;&nbsp;&nbsp;사용자 중심의 메뉴구성
        <br/>  <br/> 
        STEP 2. &nbsp;&nbsp;&nbsp;직무에 따른 초기화면 정의
        <br/>  <br/> 
        STEP 3. &nbsp;&nbsp;&nbsp;Communication 기능 강화
        <br/>  <br/> 
        STEP 4. &nbsp;&nbsp;&nbsp;업무의 연속성 강화
        <br/>  <br/> 
        STEP 5. &nbsp;&nbsp;&nbsp;프로세스 중심의 업무처리
        <br/>  <br/> 
        STEP 6. &nbsp;&nbsp;&nbsp;편리한 사용자 환경
        <br/>  <br/> 
        STEP 7. &nbsp;&nbsp;&nbsp;합리적인 가격정책
        <br/> <br/> 
      </Typography>
      <img src={'img/mainpage/mainpage2.png'} style={{width:'30%',display:'inline-block'}}/>
      </div>
    </Paper>
    :
    props.page === 3 ?
    <Paper elevation={3} sx={{padding:3,marginTop:5,marginBottom:5,width:'90%',marginLeft:'5%',marginRight:'5%'}}>
      <img src={'img/mainpage/mainpage3_title.png'} style={{width:'50%'}}/>
      <Typography variant="h6" fontWeight={'bold'} mt={2} mb={2} fontSize={15}>양식거래시스템 전개 과정
      </Typography>
      <div style={{marginLeft:'5%'}}>
      <Typography variant="h5" fontWeight={'bold'} color={'darkblue'} mt={5} mb={2} width={'60%'} display={"inline-block"} fontSize={20}>
        1단계 : 양식장의 매입/매출/회계처리 시스템 구축
        <br/>  <br/> 
        2단계 : 양식동별 사육환경 관리시스템 구축 / 유통사업 전개
        <br/>  <br/> 
        3단계 : 양식어가에 사육지원 시스템 구축 / 양식컨설팅 사업 전개
        <br/>  <br/> 
        4단계 : 양식자동화 시스템 구축 입지/시설/환경/../유통 등 종합기업
        <br/>  <br/>5단계 : 수출시스템 구축 양식 전문기업의 세계화
        <br/>  <br/> 
      </Typography>
      <img src={'img/mainpage/mainpage3.png'} style={{width:'30%',display:'inline-block'}}/>
      </div>
    </Paper>
    : null
  );
}
/* eslint-disable jsx-a11y/alt-text */
import {  Link, Paper, Typography } from "@mui/material";
import React from "react";
import { COMP, COMP2, COMP3, DENT, DENT2, DENT3, SERVICE, SERVICE2, SERVICE3, SUBMIT } from "../util/nav/navlist";

export default function HeaderNav(props: any) {

  return (
    <Paper elevation={3} sx={{padding:2,mt:4,mb:1,display:'flex'}}>
      {props.num === 0?
      <div>
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={15}>치과산업</Typography>
          {DENT.map((dent) => (
            <Link
              underline='hover'
              color="inherit"
              key={dent.title}
              variant="h6"
              href={dent.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontWeight={'bold'} fontSize={12}>&nbsp;&nbsp;&nbsp;{dent.title}</Typography>
            </Link>
          ))}
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={15}>유통제조업</Typography>
          {DENT2.map((dent) => (
            <Link
              underline='hover'
              color="inherit"
              key={dent.title}
              variant="h6"
              href={dent.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontWeight={'bold'} fontSize={12}>&nbsp;&nbsp;&nbsp;{dent.title}</Typography>
            </Link>
          ))}
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={15}>수산양식</Typography>
          {DENT3.map((dent) => (
            <Link
              underline='hover'
              color="inherit"
              key={dent.title}
              variant="h6"
              href={dent.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontWeight={'bold'} fontSize={12}>&nbsp;&nbsp;&nbsp;{dent.title}</Typography>
            </Link>
          ))}
      </div>
      :props.num === 1?
      <div>
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={15}>정부지원사업</Typography>
          {SERVICE.map((service) => (
            <Link
              underline='hover'
              color="inherit"
              key={service.title}
              variant="h6"
              href={service.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontWeight={'bold'} fontSize={12}>&nbsp;&nbsp;&nbsp;{service.title}</Typography>
            </Link>
          ))}
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={15}>고객지원</Typography>
          {SERVICE2.map((service) => (
            <Link
              underline='hover'
              color="inherit"
              key={service.title}
              variant="h6"
              href={service.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontWeight={'bold'} fontSize={12}>&nbsp;&nbsp;&nbsp;{service.title}</Typography>
            </Link>
          ))}
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={15}>공지사항</Typography>
          {SERVICE3.map((service) => (
            <Link
              underline='hover'
              color="inherit"
              key={service.title}
              variant="h6"
              href={service.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontWeight={'bold'} fontSize={12}>&nbsp;&nbsp;&nbsp;{service.title}</Typography>
            </Link>
          ))}
      </div>
      :props.num === 2?
      <div>
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={15}>개요</Typography>
          {COMP.map((comp) => (
            <Link
              underline='hover'
              color="inherit"
              key={comp.title}
              variant="h6"
              href={comp.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontWeight={'bold'} fontSize={12}>&nbsp;&nbsp;&nbsp;{comp.title}</Typography>
            </Link>
          ))}
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={15}>방향성</Typography>
          {COMP2.map((comp) => (
            <Link
              underline='hover'
              color="inherit"
              key={comp.title}
              variant="h6"
              href={comp.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontWeight={'bold'} fontSize={12}>&nbsp;&nbsp;&nbsp;{comp.title}</Typography>
            </Link>
          ))}
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={15}>인재채용</Typography>
          {COMP3.map((comp) => (
            <Link
              underline='hover'
              color="inherit"
              key={comp.title}
              variant="h6"
              href={comp.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontWeight={'bold'} fontSize={12}>&nbsp;&nbsp;&nbsp;{comp.title}</Typography>
            </Link>
          ))}
      </div>
      :props.num === 3?
      <div>
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={15}>서비스업무</Typography>
          {SUBMIT.map((submit) => (
            <Link
              underline='hover'
              color="inherit"
              key={submit.title}
              variant="h6"
              href={submit.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontWeight={'bold'} fontSize={12}>&nbsp;&nbsp;&nbsp;{submit.title}</Typography>
            </Link>
          ))}
      </div>
      :null}
    </Paper>
  );
}
/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "../Header";
import BottomNav from "../BottomNav";
import Footer from "../Footer";
import { Paper } from "@mui/material";

const theme = createTheme();

export default function SubmitIntro() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="xl" style={{width:window.innerWidth,minWidth:1500}}>
        <Header />
        <main>
          <Paper
            elevation={3}
            sx={{ marginBottom: 5, padding: 2, textAlign: "center" ,width:'90%',ml:'5%'}}
          >
            <img src={"/img/submit/submit1.png"} width="80%" />
          </Paper>
        </main>
        <BottomNav />
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

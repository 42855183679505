/* eslint-disable jsx-a11y/alt-text */
import { ThemeProvider } from '@emotion/react';
import { createTheme, CssBaseline, Container, FormControl, MenuItem, Select, Paper, Typography, Button } from '@mui/material';
import React, { useRef, useState } from 'react';
import PopupDom from '../../util/address/PopupDom';
import PopupPostCode from '../../util/address/PopupPostCode';
import BottomNav from '../BottomNav';
import Footer from '../Footer';
import Header from '../Header';
import MaskedInput from 'react-maskedinput'
import Resizer from "react-image-file-resizer";

import "react-datepicker/dist/react-datepicker.css";
import { IMG_UPLOAD, SP_SERVICE_SUBMIT_CU } from '../../sql/main_sql';

export default function Submit() {

  const theme = createTheme();

  const [inputs, setInputs] = useState({
    compnm:'',
    ownernm:'',
    mobileno:'',
    telno:'',
    bizno:'',
    dtladdr:'',
    faxno:'',
    email:'',
    homepage:'',
    rmk:'',
    syscd1:'',
    syscd2:'',
    syscd3:'',
    biztype:'', 
    bizcls:'',
  });

  const { compnm, ownernm, mobileno, telno, bizno, dtladdr, faxno, email, homepage, rmk, syscd1, syscd2, syscd3, biztype, bizcls } = inputs;

  const onChange = (keyvalue : any, e : any) => {
    const temp = e.target.value
    setInputs({
      ...inputs,
      [keyvalue]: temp,
    });
  };

  const [address,setAddress] = useState('')

  const [zip,setZip] = useState('');

  const [modalopen,setModalopen] = useState(false)

  const openModal = () => {
    setModalopen(true)
  }

  const closeModal = () => {
    setModalopen(false)
  }

  const [fileImage, setFileImage] = useState("");
  const [fileImage2, setFileImage2] = useState("");

  const [imgpath1,setImgpath1] = useState('')
  const [imgpath2,setImgpath2] = useState('')

  const imageref = useRef(null)

  const imageref2 = useRef(null)

  const onCickImageUpload = (ref : any) => {
    ref.current.click();
  };

  const resizeFile = (file : any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

  const dir = 'C:/WorkZone/UTNBASE/resources/SUBMIT/';

  const path = 'https://utnit.com/UTNBASE/resources/SUBMIT/';

  let data1 = new FormData();

  let data2 = new FormData();

  const [logo,setLogo] : any = useState(null)

  const [biz,setBiz] : any = useState(null)

  const saveFileImage = async(event: React.ChangeEvent<HTMLInputElement>) =>{
    // @ts-ignore

    const temp : File = await resizeFile(event.target.files[0])

    const newimg = new File([temp],compnm + '_logo.png',{type:'multipart/form-data',lastModified: temp.lastModified})

    setLogo(newimg)
    
    setImgpath1(path + compnm + '_logo.png' );

    setFileImage(URL.createObjectURL(event.target.files[0]));

  };

  const saveFileImage2 = async(event: React.ChangeEvent<HTMLInputElement>) =>{
    // @ts-ignore

    const temp : File = await resizeFile(event.target.files[0])

    const newimg = new File([temp],compnm + '_biz.png',{type:'multipart/form-data',lastModified: temp.lastModified})

    setBiz(newimg)

    setImgpath2(path + compnm + '_biz.png' );

    setFileImage2(URL.createObjectURL(event.target.files[0]));
  };

  const borderstyle = {
    borderRadius:3,borderWidth:1,borderColor:'lightgray',width:'100%',height:40,textAlign:'center', fontSize:15
  }
  const typostyle = {
    fontSize:15,fontWeight:'bold'
  }
  const divstyle = {
    display:'inline-block',width:'22%',margin:'1%'
  }
  const divstyle2 = {
    display:'inline-block',width:'46%',margin:'1%'
  }
  const divstyle3 = {
    display:'inline-block',width:'98%',margin:'1%'
  }
  const divstyle4 = {
    display:'inline-block',width:'48%',margin:'1%'
  }
  const divstyle5 = {
    display:'inline-block',width:'31%',margin:'1%'
  }

  const compalert = () => {
    alert('치과/기공소 명을 입력해 주세요.')
  }

  const service_cu = async() => {
    if(compnm === ''){
      compalert()
    }
    else{
      await SP_SERVICE_SUBMIT_CU(compnm,bizno,biztype,bizcls,ownernm,address,zip,dtladdr,mobileno,telno,faxno,homepage,imgpath1,imgpath2,rmk,
      syscd1,syscd2,syscd3)
      
      data1.append('file',logo)
      data1.append('dir',dir);

      
      data2.append('file',biz)
      data2.append('dir',dir);

      await IMG_UPLOAD(data1)
      await IMG_UPLOAD(data2)

      alert('등록 되었습니다.')
    }
  }

  return(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="xl" style={{width:window.innerWidth,minWidth:1500}}>
        <Header />
        <main>
        <Paper elevation={3} sx={{padding:3,width:'90%',ml:'5%'}}>
        <img src={"/img/submit/submit_title.png"} width={'25%'}/>
          <div style={{textAlign:'center',width:'60%',marginLeft:'20%',marginRight:'20%',marginTop:'3%'}}>
            <div>
              <div style={divstyle}>
                <Typography style={typostyle}>기관 명</Typography>
                <input onChange={e => onChange('compnm',e)} value={compnm} style={borderstyle as React.CSSProperties} />
              </div>
              <div style={divstyle}>
                <Typography style={typostyle}>대표자 명</Typography>
                <input onChange={e => onChange('ownernm',e)} value={ownernm} style={borderstyle as React.CSSProperties} />
              </div>
              <div style={divstyle}>
                <Typography style={typostyle}>대표 핸드폰</Typography>
                <MaskedInput mask="111-1111-1111" value={mobileno} placeholder='000-0000-000' onChange={e => onChange('mobileno',e)}
                  style={borderstyle as React.CSSProperties}/>
              </div>
              <div style={divstyle}>
                <Typography style={typostyle}>사업자 번호</Typography>
                <MaskedInput mask="111-11-11111"  value={bizno} placeholder='000-00-00000' onChange={e => onChange('bizno',e)}
                  style={borderstyle as React.CSSProperties}/>
              </div>
            </div>
            <div>
              <div style={divstyle}>
                <Typography style={typostyle}>업태</Typography>
                <input value={biztype} onChange={e => onChange('biztype',e)} style={borderstyle as React.CSSProperties}/>
              </div>
              <div style={divstyle}>
                <Typography style={typostyle}>종목</Typography>
                <input value={bizcls}  onChange={e => onChange('bizcls',e)} style={borderstyle as React.CSSProperties}/>
              </div>
              <div style={divstyle}>
                <Typography style={typostyle}>전화번호</Typography>
                <input value={telno} onChange={e => onChange('telno',e)} style={borderstyle as React.CSSProperties}/>
              </div>
              <div style={divstyle}>
                <Typography style={typostyle}>Fax번호</Typography>
                <input onChange={e => onChange('faxno',e)} value={faxno} style={borderstyle as React.CSSProperties}/>
              </div>
            </div>
            <div>
              <div style={divstyle}>
                <Typography style={typostyle}>우편번호</Typography>
                <input disabled value={zip} style={borderstyle as React.CSSProperties}/>
              </div>
              <div style={divstyle}>
                <Typography style={typostyle}>주소</Typography>
                <input disabled value={address} style={borderstyle as React.CSSProperties}/>
              </div>
              <div style={divstyle2}>
                <Typography style={typostyle}>상세주소</Typography>
                <input onChange={e => onChange('dtladdr',e)} value={dtladdr} style={borderstyle as React.CSSProperties}/>
              </div>
            </div>
            <div style={divstyle}>
              <Button style={{width:'100%',height:40,fontSize:12}} variant="contained" onClick={openModal}>주소 검색</Button>
            </div>
            <div id='popupDom'>
              {modalopen && (
                <PopupDom>
                  <PopupPostCode setAddress={setAddress} setZip={setZip} onClose={closeModal} />
                </PopupDom>
              )}
            </div>
            <div style={{display:'flex',width:'60%',marginLeft:'20%',marginRight:'20%',marginTop:'4%',marginBottom:'4%'}}>
              <div style={divstyle4}>
                <Typography style={typostyle}>회사로고</Typography>
                <input
                    name="imageUpload"
                    type="file"
                    accept="image/*"
                    onChange={saveFileImage}
                    ref={imageref}
                    style={{display : 'none'}} />
                <Button variant="outlined" onClick={() => compnm === '' ? compalert() : onCickImageUpload(imageref)}
                  style={{width:150,height:40,fontSize:12,marginTop:10}}>파일선택</Button>
                <Paper elevation={3} style={{width:'100%',aspectRatio: 1,textAlign:'center',marginTop:'4%'}}>{fileImage && ( <img alt="sample" src={fileImage}
                    style={{width:'100%',aspectRatio: 1}} /> )}
                </Paper>
              </div>
              <div style={divstyle4}>
                <Typography style={typostyle}>사업자등록증</Typography>
                <input
                    name="imageUpload2"
                    type="file"
                    accept="image/*"
                    onChange={saveFileImage2}
                    ref={imageref2}
                    style={{display : 'none'}} />
                <Button variant="outlined" onClick={() => compnm === '' ? compalert() : onCickImageUpload(imageref2)}
                  style={{width:150,height:40,fontSize:12,marginTop:10}}>파일선택</Button>
                <Paper elevation={3} style={{width:'100%',aspectRatio: 1,textAlign:'center',marginTop:'4%'}}>{fileImage2 && ( <img alt="sample" src={fileImage2}
                    style={{width:'100%',aspectRatio: 1}} /> )}
                </Paper>
              </div>
            </div>
            <div>
              <div style={divstyle5}>
                <Typography style={typostyle}>신청서비스</Typography>
                <FormControl style={{width:'100%'}}>
                  <Select
                    value={syscd1}
                    onChange={e => onChange('syscd1',e)}
                    style={borderstyle as React.CSSProperties}
                  >
                    <MenuItem value={'DENT_WORK'}>치과경영관리</MenuItem>
                    <MenuItem value={'LAB_LINK'}>치과기공물관리</MenuItem>
                    <MenuItem value={'DENT_TALK'}>덴톡서비스</MenuItem>
                    <MenuItem value={'LAB_WORK'}>기공소경영관리</MenuItem>
                    <MenuItem value={'DENT_LINK'}>기공물진행관리</MenuItem>
                    <MenuItem value={'YOUNGBI'}>영업비서</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div style={divstyle5}>
                <Typography style={typostyle}>신청서비스2</Typography>
                <FormControl style={{width:'100%'}}>
                  <Select
                    value={syscd2}
                    onChange={e => onChange('syscd2',e)}
                    style={borderstyle as React.CSSProperties}
                  >
                    <MenuItem value={'DENT_WORK'}>치과경영관리</MenuItem>
                    <MenuItem value={'LAB_LINK'}>치과기공물관리</MenuItem>
                    <MenuItem value={'DENT_TALK'}>덴톡서비스</MenuItem>
                    <MenuItem value={'LAB_WORK'}>기공소경영관리</MenuItem>
                    <MenuItem value={'DENT_LINK'}>기공물진행관리</MenuItem>
                    <MenuItem value={'YOUNGBI'}>영업비서</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div style={divstyle5}>
                <Typography style={typostyle}>신청서비스3</Typography>
                <FormControl style={{width:'100%'}}>
                  <Select
                    value={syscd3}
                    onChange={e => onChange('syscd3',e)}
                    style={borderstyle as React.CSSProperties}
                  >
                    <MenuItem value={'DENT_WORK'}>치과경영관리</MenuItem>
                    <MenuItem value={'LAB_LINK'}>치과기공물관리</MenuItem>
                    <MenuItem value={'DENT_TALK'}>덴톡서비스</MenuItem>
                    <MenuItem value={'LAB_WORK'}>기공소경영관리</MenuItem>
                    <MenuItem value={'DENT_LINK'}>기공물진행관리</MenuItem>
                    <MenuItem value={'YOUNGBI'}>영업비서</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div>
              <div style={divstyle4}>
                  <Typography style={typostyle}>이메일</Typography>
                  <input onChange={e => onChange('email',e)} value={email} style={borderstyle as React.CSSProperties}/>
              </div>
              <div style={divstyle4}>
                <Typography style={typostyle}>홈페이지</Typography>
                <input onChange={e => onChange('homepage',e)} value={homepage} style={borderstyle as React.CSSProperties}/>
              </div>
              <div style={divstyle3}>
                <Typography style={typostyle}>회사소개</Typography>
                <input onChange={e => onChange('rmk',e)} value={rmk} style={borderstyle as React.CSSProperties}/>
              </div>
            </div>
          </div>
          <div style={{alignItems:'center',justifyContent:'center',textAlign:'center',margin:50}}>
            <Button style={{width:250,height:40,fontSize:15}} variant="contained" onClick={() => service_cu()}>서비스 등록</Button>
          </div>
        </Paper>
        </main>
        <BottomNav />
        <Footer />
      </Container>
    </ThemeProvider>
  )
}


export async function IMG_UPLOAD(form : FormData){

  const url = 'https://utnit.com/SALES_F/img_upload.do';
  fetch(url, {
    method: 'POST',
    headers: {
    },
    body: form,
  });
  // .then(res => res.json())
  // .then(data => (result = data));
}

export async function SP_SERVICE_SUBMIT_CU(compnm : string, bizno : string, biztype : string, bizcls : string, ownernm : string,
  addr : string, zipcd : string, dtladdr : string, mobileno : string, telno : string, faxno : string, homepage : string, logopath : string,
  bizpath : string, rmk : string, syscd1 : string, syscd2 : string, syscd3 : string){

  const url = 'https://utnit.com/UTNBASE/sp_service_submit_cu.do?compnm=' + compnm + '&bizno=' + bizno + '&biztype=' + biztype + '&bizcls=' + bizcls + '&ownernm=' + ownernm
  + '&addr=' + addr + '&zipcd=' + zipcd + '&dtladdr=' + dtladdr + '&mobileno=' + mobileno + '&telno=' + telno + '&faxno=' + faxno + '&homepage=' + homepage
  + '&logopath=' + logopath + '&bizpath=' + bizpath + '&rmk=' + rmk + '&syscd1=' + syscd1 + '&syscd2=' + syscd2 + '&syscd3=' + syscd3;
    try {
      const response = await fetch(
        url
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
}

export async function SP_BOARD_INFO_R(compcd : string){

  const url = 'https://utnit.com/UTNBASE/sp_board_info_r.do?compcd=' + compcd
    try {
      const response = await fetch(
        url
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
}
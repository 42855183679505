import { Button } from '@mui/material';
import React from 'react';
import DaumPostcode from "react-daum-postcode";
 
const PopupPostCode = (props : any) => {
	// 우편번호 검색 후 주소 클릭 시 실행될 함수, data callback 용
    const handlePostCode = (data : any) => {
        let fullAddress = data.address;
        let extraAddress = ''; 
        
        if (data.addressType === 'R') {
          if (data.bname !== '') {
            extraAddress += data.bname;
          }
          if (data.buildingName !== '') {
            extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
          }
          fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        props.setAddress(fullAddress)
        props.setZip(data.zonecode)
        props.onClose()
    }
 
    const postCodeStyle = {
        display: "inline-block",
        //position: "absolute",
        width: "100%",
        padding: "2%",
        zIndex:100,
      };

    return(
        <div>
            <DaumPostcode style={postCodeStyle} onComplete={handlePostCode} />
            <Button variant="contained" onClick={() => {props.onClose()}} sx={{fontSize:12}} className='postCode_btn'>닫기</Button>
        </div>
    )
}
 
export default PopupPostCode;
/* eslint-disable jsx-a11y/alt-text */
import {  Paper, Typography } from "@mui/material";
import React from "react";

export default function CustView(props: any) {

  return (
    <Paper elevation={3} sx={{padding:3, backgroundColor:'#fbfbfb', marginBottom:1,width:'90%',ml:'5%'}}>
      <Typography variant="h4" align={'center'} fontWeight={'bold'} mb={1} fontSize={25}>주요고객기업</Typography>
      <Typography variant="h6" align={'center'} fontWeight={'bold'} mb={1.5} color={'text.secondary'} fontSize={20}>Our Clients</Typography>
      <div style={{display:'flex'}}>
        <Paper sx={{width:'18%',height:'1dw',textAlign:'center',margin:1.5}}>
          <img src={'img/custlogo/custlogo1.png'} style={{marginTop:'20%',width:'70%',height:'33%'}}/>
        </Paper>
        <Paper sx={{width:'18%',height:'1dw',textAlign:'center',margin:1.5}}>
          <img src={'img/custlogo/custlogo2.png'} style={{marginTop:'20%',width:'70%',height:'33%'}}/>
        </Paper>
        <Paper sx={{width:'18%',height:'1dw',textAlign:'center',margin:1.5}}>
          <img src={'img/custlogo/custlogo3.png'} style={{marginTop:'20%',width:'70%',height:'33%'}}/>
        </Paper>
        <Paper sx={{width:'18%',height:'1dw',textAlign:'center',margin:1.5}}>
          <img src={'img/custlogo/custlogo4.png'} style={{marginTop:'20%',width:'70%',height:'33%'}}/>
        </Paper>
        <Paper sx={{width:'18%',height:'1dw',textAlign:'center',margin:1.5}}>
          <img src={'img/custlogo/custlogo5.png'} style={{marginTop:'20%',width:'70%',height:'33%'}}/>
        </Paper>
        <Paper sx={{width:'18%',height:'1dw',textAlign:'center',margin:1.5}}>
          <img src={'img/custlogo/custlogo6.png'} style={{marginTop:'20%',width:'70%',height:'33%'}}/>
        </Paper>
        <Paper sx={{width:'18%',height:'1dw',textAlign:'center',margin:1.5}}>
          <img src={'img/custlogo/custlogo7.png'} style={{marginTop:'20%',width:'70%',height:'33%'}}/>
        </Paper>
      </div>
    </Paper>
  );
}
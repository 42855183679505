import React from 'react';
import { BrowserRouter,Routes, Route } from "react-router-dom";
import BoardMain from './main/board/BoardMain';
import Direction from './main/comp/direction';
import Introduct from './main/comp/Introduct';
import Main from './main/Main';
import DentTalk from './main/product/DentTalk';
import DentWork from './main/product/DentWork';
import LabLink from './main/product/LabLink';
import LabWork from './main/product/LabWork';
import SmartF from './main/product/SmartF';
import SmartW from './main/service/SmartW';
import SsmartF from './main/service/SsmartF';
import Voucher from './main/service/Voucher';
import Submit from './main/submit/Submit';
import SubmitIntro from './main/submit/SubmitIntro';
import { BrowserView,MobileView } from "react-device-detect";
import Maps from './main/comp/map';

function App() {
  return (
    <div>
      <BrowserView>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/front/DentWork" element={<DentWork />} />
            <Route path="/front/LabLink" element={<LabLink />} />
            <Route path="/front/DentTalk" element={<DentTalk />} />
            <Route path="/front/LabWork" element={<LabWork />} />
            <Route path="/front/SmartF" element={<SmartF />} />
            <Route path="/front/SmartW" element={<SmartW />} />
            <Route path="/front/SsmartF" element={<SsmartF />} />
            <Route path="/front/Voucher" element={<Voucher />} />
            <Route path="/front/Introduct" element={<Introduct />} />
            <Route path="/front/Direction" element={<Direction />} />
            <Route path="/front/SubmitIntro" element={<SubmitIntro />} />
            <Route path="/front/Submit" element={<Submit />} />
            <Route path="/front/BoardMain" element={<BoardMain />} />
            <Route path="/front/Map" element={<Maps />} />
          </Routes>
        </BrowserRouter>
      </BrowserView>
      <MobileView>
        <div>
          데스크탑 전용 페이지 입니다.
        </div>
      </MobileView>
    </div>
  );
}

export default App;

/* eslint-disable jsx-a11y/alt-text */
import {  Link, Paper, Typography } from "@mui/material";
import React from "react";
import { DENT, DENT2, DENT3, SERVICE, SERVICE2, SERVICE3, COMP, COMP2, COMP3, SUBMIT } from "../util/nav/navlist";

export default function BottomNav(props: any) {
 
  return (
    <Paper elevation={3} sx={{padding:3,marginTop:5,marginBottom:1,display:'flex',width:'90%',ml:'5%'}}>
      <div style={{width:'25%'}}>
        <Typography variant="h5" fontWeight={'bold'} fontSize={15}>제품</Typography>
        <div style={{marginLeft:15}}>
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={12}>치과산업</Typography>
          {DENT.map((dent) => (
            <Link
              underline='hover'
              color="inherit"
              key={dent.title}
              variant="h6"
              href={dent.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontSize={12}>&nbsp;&nbsp;&nbsp;{dent.title}</Typography>
            </Link>
          ))}
        </div>
        <div style={{marginLeft:15}}>
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={12}>유통제조업</Typography>
          {DENT2.map((dent) => (
            <Link
              underline='hover'
              color="inherit"
              key={dent.title}
              variant="h6"
              href={dent.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontSize={12}>&nbsp;&nbsp;&nbsp;{dent.title}</Typography>
            </Link>
          ))}
        </div>
        <div style={{marginLeft:15}}>
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={12}>수산양식</Typography>
          {DENT3.map((dent) => (
            <Link
              underline='hover'
              color="inherit"
              key={dent.title}
              variant="h6"
              href={dent.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontSize={12}>&nbsp;&nbsp;&nbsp;{dent.title}</Typography>
            </Link>
          ))}
        </div>
      </div>
      <div style={{width:'25%'}}>
        <Typography variant="h5" fontWeight={'bold'} fontSize={15}>서비스</Typography>
        <div style={{marginLeft:15}}>
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={12}>정부지원사업</Typography>
          {SERVICE.map((service) => (
            <Link
              underline='hover'
              color="inherit"
              key={service.title}
              variant="h6"
              href={service.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontSize={12}>&nbsp;&nbsp;&nbsp;{service.title}</Typography>
            </Link>
          ))}
        </div>
        <div style={{marginLeft:15}}>
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={12}>고객지원</Typography>
          {SERVICE2.map((service) => (
            <Link
              underline='hover'
              color="inherit"
              key={service.title}
              variant="h6"
              href={service.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontSize={12}>&nbsp;&nbsp;&nbsp;{service.title}</Typography>
            </Link>
          ))}
        </div>
        <div style={{marginLeft:15}}>
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={12}>공지사항</Typography>
          {SERVICE3.map((service) => (
            <Link
              underline='hover'
              color="inherit"
              key={service.title}
              variant="h6"
              href={service.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontSize={12}>&nbsp;&nbsp;&nbsp;{service.title}</Typography>
            </Link>
          ))}
        </div>
      </div>
      <div style={{width:'25%'}}>
        <Typography variant="h5" fontWeight={'bold'} fontSize={15}>회사소개</Typography>
        <div style={{marginLeft:15}}>
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={12}>개요</Typography>
          {COMP.map((comp) => (
            <Link
              underline='hover'
              color="inherit"
              key={comp.title}
              variant="h6"
              href={comp.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontSize={12}>&nbsp;&nbsp;&nbsp;{comp.title}</Typography>
            </Link>
          ))}
        </div>
        <div style={{marginLeft:15}}>
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={12}>방향성</Typography>
          {COMP2.map((comp) => (
            <Link
              underline='hover'
              color="inherit"
              key={comp.title}
              variant="h6"
              href={comp.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontSize={12}>&nbsp;&nbsp;&nbsp;{comp.title}</Typography>
            </Link>
          ))}
        </div>
        <div style={{marginLeft:15}}>
          <Typography variant="h6" fontWeight={'bold'} mt={1} fontSize={12}>인재채용</Typography>
          {COMP3.map((comp) => (
            <Link
              underline='hover'
              color="inherit"
              key={comp.title}
              variant="h6"
              href={comp.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontSize={12}>&nbsp;&nbsp;&nbsp;{comp.title}</Typography>
            </Link>
          ))}
        </div>
      </div>
      <div style={{width:'25%'}}>
        <Typography variant="h5" fontWeight={'bold'} fontSize={15}>서비스신청</Typography>
        <div style={{marginLeft:15}}>
          {SUBMIT.map((submit) => (
            <Link
              underline='hover'
              color="inherit"
              key={submit.title}
              variant="h6"
              href={submit.url}
              style={{fontSize:'90%',display:'block',fontWeight:'bold'}}
            >
              <Typography fontSize={12}>{submit.title}</Typography>
            </Link>
          ))}
        </div>
      </div>
    </Paper>
  );
}
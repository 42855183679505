/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay,Navigation, Pagination, EffectCoverflow } from "swiper";

// Import Swiper styles
import "swiper/swiper.css";
import { Paper } from "@mui/material";

SwiperCore.use([Navigation, Pagination, EffectCoverflow,Autoplay]);

export default function SwiperCoverflow() {

  return (
    <div style={{width:'90%',marginLeft:'5%',marginRight:'5%'}}>
      <Paper elevation={3} style={{marginBottom:20}}>
      <Swiper
        loop
        autoplay={{
          delay: 2500,
          disableOnInteraction: false
        }}
        effect="coverflow"
        coverflowEffect={{
          rotate: 40,
          stretch: 0,
          depth: 600,
          modifier: 1,
          slideShadows: true
        }}
        slidesPerView={2}
        centeredSlides
        style={{ width:'100%',padding:'5%',backgroundColor:'lightsteelblue' }}
        >
        <SwiperSlide>
          <img src={'img/slide/slidephoto1.png'} width={'100%'}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={'img/slide/slidephoto2.png'} width={'100%'}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={'img/slide/slidephoto3.png'} width={'100%'}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={'img/slide/slidephoto4.png'} width={'100%'}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={'img/slide/slidephoto5.png'} width={'100%'}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={'img/slide/slidephoto6.png'} width={'100%'}/>
        </SwiperSlide>
      </Swiper>
      </Paper>
    </div>
  );
}
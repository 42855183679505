export const DENT = [
  { title: '- DENT_WORK', url: '/front/DentWork' },
  { title: '- LAB_LINK', url: '/front/LabLink' },
  { title: '- DENT_TALK', url: '/front/DentTalk' },
  { title: '- LAB_WORK', url: '/front/LabWork' },
  { title: '- DENT_LINK', url: '#' },
]; 

export const DENT2 = [
  { title: '- SMART_F', url: '/front/SmartF' },
  { title: '- KODA(준비중)', url: '#' },
]; 

export const DENT3 = [
  { title: '- FISH_CULT(준비중)', url: '#' },
]; 

export const SERVICE = [
  { title: '- 스마트공방', url: '/front/SmartW' },
  { title: '- 스마트팩토리', url: '/front/SsmartF' },
  { title: '- 비대면바우처', url: '/front/Voucher' },
]; 

export const SERVICE2 = [
  { title: '- 고도화 서비스(준비중)', url: '#' },
  { title: '- 고객지원 서비스(준비중)', url: '#' },
  { title: '- 원격지원 서비스(준비중)', url: '#' },
]; 

export const SERVICE3 = [
  { title: '- 공지사항', url: '/front/BoardMain' },
];

export const COMP = [
  { title: '- 주요연혁', url: '/front/Introduct' },
  { title: '- 저작권현황(준비중)', url: '#' },
  { title: '- 찾아오는 길', url: '/front/Map' },
];

export const COMP2 = [
  { title: '- 우리의 목표', url: '/front/Direction' },
  { title: '- 사명(준비중)', url: '#' },
];

export const COMP3 = [
  { title: '- 채용안내(준비중)', url: '#' },
  { title: '- 복리후생(준비중)', url: '#' },
];

export const SUBMIT = [
  { title: '- 서비스개요', url: '/front/SubmitIntro' },
  { title: '- 서비스신청', url: '/front/Submit' },
  { title: '- 개인정보방침', url: 'https://utnit.com/SALES_F/resources/youngbi.html' },
];
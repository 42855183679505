/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import {Map,MapMarker, ZoomControl} from 'react-kakao-maps-sdk'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../Header';
import BottomNav from '../BottomNav';
import Footer from '../Footer';
import { Paper } from '@mui/material';

const theme = createTheme();

export default function Maps() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="xl" style={{width:window.innerWidth,minWidth:1500}}>
        <Header/>
        <main>
          <Paper elevation={3} sx={{marginBottom:5,padding:2,textAlign:'center',width:'90%',ml:'5%'}}>
            <Map
              center={{ lat: 37.565727719502426, lng: 127.05478908965252 }}
              style={{ width: "100%", height: "500px" }}
            >
              <MapMarker position={{ lat: 37.565727719502426, lng: 127.05478908965252 }} />
              <ZoomControl position={kakao.maps.ControlPosition.TOPRIGHT} />
            </Map>
          </Paper>
        </main>
        <BottomNav />
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

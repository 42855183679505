/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import { useState } from 'react';
import HeaderNav from './HeaderNav';
import { useNavigate } from 'react-router-dom';

export default function Header() {

  const sections = [
    { title: '제품', url: '#' },
    { title: '서비스', url: '#' },
    { title: '회사소개', url: '#' },
    { title: '서비스신청', url: '#' },
  ];

  const [bool1,setBool1] = useState(false)
  const [bool2,setBool2] = useState(false)
  const [bool3,setBool3] = useState(false)
  const [bool4,setBool4] = useState(false)

  const navigate = useNavigate();

  const boolsave = (idx,state) => {
    if(idx === 0){
      setBool1(state)
    }
    else if(idx === 1){
      setBool2(state)
    }
    else if(idx === 2){
      setBool3(state)
    }
    else if(idx === 3){
      setBool4(state)
    }
  }

  const boolchk = (idx) => {
    if(idx === 0){
      return bool1
    }
    else if(idx === 1){
      return bool2
    }
    else if(idx === 2){
      return bool3
    }
    else if(idx === 3){
      return bool4
    }
    return false
  }

  return (
    <React.Fragment>
      <Toolbar sx={{width:'90%',ml:'5%'}}>
        <img src={'/img/utn_logo_g.png'} width={250} style={{paddingTop:15,marginRight:'8%'}} onClick={() => navigate("/")}/>
        {sections.map((section,idx) => (
          <Link
            onMouseEnter={() => boolsave(idx,true)}
            onMouseLeave={() => boolsave(idx,false)}
            underline='hover'
            color="inherit"
            noWrap
            key={section.title}
            variant="body2"
            sx={{width:'100%',padding:4}}
            style={{fontWeight:'bold',fontSize:20}}
          >
            {section.title}
            {boolchk(idx) ?
            <div style={{position:'absolute',zIndex:100,top:60}}>
              <HeaderNav num={idx} />
            </div>
            :
            null}
          </Link>
        ))}
      </Toolbar>
    </React.Fragment>
  );
}
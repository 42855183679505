/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
//import Grid from '@mui/material/Grid';
// import FeaturedPost from './FeaturedPost';
// import Main from './Main';
// import Sidebar from './Sidebar';
import Footer from './Footer';
import Slider from './MainSlide';
import CustView from './CustView';
import MainPage from './MainPage';
import BottomNav from './BottomNav';
import { Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const theme = createTheme();

export default function Main() {
  const navigate = useNavigate();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="xl" style={{width:window.innerWidth,minWidth:1500}}>
        <Header/>
        <main>
          <Slider />
          <Paper elevation={0} sx={{marginTop:5,marginBottom:5,padding:2}}>
            <div style={{width:'36%',display:'inline-block',marginLeft:'9%',marginRight:'5%',textAlign:'right'}}>
              <img src={'/img/main_dt.png'} width='100%'/>
              <Button variant='contained' onClick={() => navigate('/front/DentTalk')} style={{width:'100%',fontSize:15}}>바로가기</Button>
            </div>
            <div style={{width:'36%',display:'inline-block',marginLeft:'5%',marginRight:'9%',textAlign:'right'}}>
              <img src={'/img/main_lw.png'} width='100%'/>
              <Button variant='contained' onClick={() => navigate('/front/LabWork')} style={{width:'100%',fontSize:15}}>바로가기</Button>
            </div>
          </Paper>
          <MainPage page={1}/>
          <MainPage page={2}/>
          <MainPage page={3}/>
          <CustView />
        </main>
        <BottomNav />
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

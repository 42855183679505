/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../Header';
import BottomNav from '../BottomNav';
import Footer from '../Footer';

import Box from '@mui/material/Box';
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { SP_BOARD_INFO_R } from '../../sql/main_sql';

const theme = createTheme();

export default function BoardMain() {

  const [rowData,setRowData] = useState([
    {
      id: 0,
      title: "",
      text:"",
      filepath:'',
    },
  ]);

  const columnData = [
    { field: "id", headerName: "순번" , flex: 1},
    {
      field: "title",
      headerName: "제목",
      flex: 3.5
    },
    {
      field:'text',
      headerName: "내용",
      flex: 5.5
    }
  ];

  const downloadFile = (filepath : any) => {
    const path = filepath

    const download = () => {
      fetch(path)
        .then(response => {
          response.blob().then(blob => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = path.substring(path.lastIndexOf('/')+1, path.length);
              a.click();
          });
          //window.location.href = response.url;
      });
    }
    download()
  }

  const [loading,setLoading] = useState(true)

  const [selected,setSelected] = useState(0)

  const getData = async() => {

    setLoading(true)

    let temp = await SP_BOARD_INFO_R('UTN')

    console.log(temp)

    let data : any = []

    for( let i = 0 ; i < temp.length ; i++){
      data = [...data,{id: temp[i].seq, title: temp[i].title, text: temp[i].text,
        filepath: temp[i].filepath,crembr: temp[i].crembr, credate: temp[i].credate}]
    }

    setRowData(data)

    setLoading(false)
  }

  const mapping = (arr : any,col : any) => {
    return (
      arr.id === selected ?
        col === 'title' ?
        <Typography sx={{fontSize:20,margin:'1vw',fontWeight:'bold'}}>
          {arr.title}
        </Typography>
        :
        col === 'text' ?
        <Typography sx={{fontSize:15,margin:'1vw'}}>
          {arr.text}
        </Typography>
        :
        col === 'file' ?
          (arr.filepath === null) || (arr.filepath === '') ? null
          :
          <div style={{margin:'1vw',display:'flex'}}>
            <Typography sx={{fontSize:15,margin:'0.5vw'}}>
              첨부파일 : 
            </Typography>
            <Button onClick={() => downloadFile(arr.filepath)} sx={{fontSize:12}}>
              {
                arr.filepath.substring(arr.filepath.lastIndexOf('/')+1, arr.filepath.length)
              }
            </Button>
          </div>
        :
        null
      :
      null
    )
  }

  useEffect(() => {
    getData()
  },[])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="xl" style={{width:window.innerWidth,minWidth:1500}}>
        <Header/>
        <main style={{width:'90%',marginLeft:'5%'}}>
          <Typography sx={{fontSize:25,margin:'1vw',fontWeight:'bold'}}>
            공지사항
          </Typography>
          <Box sx={{ height: '70vh', width: '100%' }}>
            {selected === 0 ?
            <DataGrid
              sx={{fontSize:15}}
              rows={rowData}
              columns={columnData}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              disableRowSelectionOnClick
              loading={loading}
              onRowClick={(params) => setSelected(Number(params.id))}
              autoPageSize
            />
            :
            <Paper elevation={3} sx={{padding:3,height: '70vh',}}>
              <Box sx={{margin:'1%',width:'100%',borderBottom:'1px solid black',display:'flex',justifyContent: 'space-between'}}>
                {rowData.map((data) => mapping(data,'title'))}
                <Button onClick={() => setSelected(0)} variant={'contained'} sx={{fontSize:12,margin:'1vw'}}>목록으로</Button>
              </Box>
              {rowData.map((data) => mapping(data,'file'))}
              <Box sx={{margin:'2vw',width:'100%'}}>
                {rowData.map((data) => mapping(data,'text'))}
              </Box>
            </Paper>
            }
          </Box>
        </main>
        <BottomNav />
        <Footer />
      </Container>
    </ThemeProvider>
  );
}
